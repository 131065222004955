import React from "react"
import Layout from "../components/layout"
import Navigation2 from "../components/navigation2"
import HomeContacto from "../components/home-contacto"
import { Link } from "gatsby"
import Firmando from "../images/firmando.jpg"

const IndexPage = () => (
  <Layout>
    <div className="w-full lg:w-10/12 mx-auto">
      <Navigation2 />

      <div className="w-full lg:flex mx-auto">
        <div className="w-full lg:w-11/12 px-5 lg:px-0 mt-10 lg:mt-0">
          <Link
            to="/publicaciones"
            className="text-sm mb-5 block hover:underline"
          >
            Ver todas las publicaciones
          </Link>
          <h1 className="text-3xl lg:mt-10 lg:mb-10">
            Lorem Ipsum Etiam Elefiend
          </h1>

          <div className="post-preview mt-8 mb-20 lg:flex">
            <div className="w-full lg:w-1/3">
              <img
                src={Firmando}
                alt="Firmando"
                className="w-2/3 lg:w-full lg:p-5 rounded mb-10"
              />
            </div>
            <div className="w-full lg:w-2/3">
              <p className="preview">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                accumsan consectetur eleifend. Aliquam erat volutpat. Vestibulum
                non arcu varius, ullamcorper lorem consectetur, fringilla velit.
                Etiam ante dolor, sollicitudin eget elit in, rhoncus rutrum
                erat. Donec quis justo accumsan, porta lacus ac, imperdiet
                felis.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <HomeContacto />
  </Layout>
)

export default IndexPage
